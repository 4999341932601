import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'planning',
    loadComponent: () => import('./pages/planning/planning.component').then((m) => m.PlanningComponent),
  },
  {
    path: 'pacientes',
    loadComponent: () => import('./pages/pacientes/pacientes.component').then((m) => m.PacientesComponent),
  },
  {
    path: 'historia-paciente',
    loadComponent: () =>
      import('./pages/historia-paciente/historia-paciente.component').then((m) => m.HistoriaPacienteComponent),
  },
  {
    path: 'ayuda',
    loadComponent: () => import('./pages/ayuda/ayuda.component').then((m) => m.AyudaComponent),
  },
];
